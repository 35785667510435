@import 'reset-css';

html,
body {
  width: 100%;
  height: 100%;
}

#app {
  background-color: #f1f2f8;
  width: 100%;
  height: 100%;
}

body {
  line-height: 1.5;
  color: #363644;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
table,
div,
button,
p {
  font-family: Pretendard, sans-serif;
}

#browser-info-banner {
  display: none;
}

#browser-info-banner[data-unsupported-browser='true'] {
  text-align: center;
  background-color: white;
  padding: 13px 0;
  display: block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

a:visited {
  color: #3a5be7;
}
